//Uncomment this line on creating a translation file
//import { localization } from "../localizationManager";

export var englishStrings = {
  groupButton: "Group By Me",
  ungroupButton: "Ungroup By Me",
  selectButton: "Select Me",
  hideColumn: "Hide column",
  showColumn: "Show column",
  makePrivateColumn: "Make column private",
  makePublicColumn: "Make column public",
  moveToDetail: "Move to Detail",
  showAsColumn: "Show as Column",
  filterPlaceholder: "Search...",
  removeRows: "Remove rows",
  showLabel: "Show",
  entriesLabel: "entries",
  visualizer_text: "Texts in table",
  visualizer_wordcloud: "Wordcloud",
  chartType_bar: "Bar",
  chartType_stackedbar: "Stacked Bar",
  chartType_doughnut: "Doughnut",
  chartType_pie: "Pie",
  chartType_scatter: "Scatter",
  chartType_gauge: "Gauge",
  chartType_bullet: "Bullet",
  hideButton: "Hide",
  makePrivateButton: "Make private",
  makePublicButton: "Make public",
  showButton: "Show",
  resetFilter: "Reset Filter",
  changeLocale: "Change Locale",
  clearButton: "Clear",
  addElement: "Choose question to show...",
  defaultOrder: "Default Order",
  ascOrder: "Ascending",
  descOrder: "Descending",
  showMinorColumns: "Show minor columns",
  otherCommentTitle: "Other items and comments",
  showPercentages: "Show percentages",
  hidePercentages: "Hide percentages",
  pdfDownloadCaption: "PDF",
  xlsxDownloadCaption: "Excel",
  csvDownloadCaption: "CSV",
  saveDiagramAsPNG: "Download plot as a png",
  hideEmptyAnswers: "Hide empty answers",
  showEmptyAnswers: "Show empty answers",
  "topNValueText-1": "All answers",
  "topNValueText5": "Top 5 answers",
  "topNValueText10": "Top 10 answers",
  "topNValueText20": "Top 20 answers",
};

//Uncomment these two lines on creating a translation file. You should replace "en" and enStrings with your locale ("fr", "de" and so on) and your variable.
//localization.locales["en"] = englishStrings;
//localization.localeNames["en"] = "English";
